import { jsx as it } from "react/jsx-runtime";
import ut, { createContext as zt, useRef as Ot, useState as O, useCallback as T, useEffect as kt, useMemo as Mt, useContext as Gt } from "react";
function _e(s, t, e) {
  return Math.max(t, Math.min(s, e));
}
const f = {
  toVector(s, t) {
    return s === void 0 && (s = t), Array.isArray(s) ? s : [s, s];
  },
  add(s, t) {
    return [s[0] + t[0], s[1] + t[1]];
  },
  sub(s, t) {
    return [s[0] - t[0], s[1] - t[1]];
  },
  addTo(s, t) {
    s[0] += t[0], s[1] += t[1];
  },
  subTo(s, t) {
    s[0] -= t[0], s[1] -= t[1];
  }
};
function Ct(s, t, e) {
  return t === 0 || Math.abs(t) === 1 / 0 ? Math.pow(s, e * 5) : s * t * e / (t + e * s);
}
function Nt(s, t, e, i = 0.15) {
  return i === 0 ? _e(s, t, e) : s < t ? -Ct(t - s, e - t, i) + t : s > e ? +Ct(s - e, e - t, i) + e : s;
}
function ve(s, [t, e], [i, n]) {
  const [[o, r], [c, l]] = s;
  return [Nt(t, o, r, i), Nt(e, c, l, n)];
}
function ye(s, t) {
  if (typeof s != "object" || s === null) return s;
  var e = s[Symbol.toPrimitive];
  if (e !== void 0) {
    var i = e.call(s, t || "default");
    if (typeof i != "object") return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (t === "string" ? String : Number)(s);
}
function be(s) {
  var t = ye(s, "string");
  return typeof t == "symbol" ? t : String(t);
}
function g(s, t, e) {
  return t = be(t), t in s ? Object.defineProperty(s, t, {
    value: e,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : s[t] = e, s;
}
function Lt(s, t) {
  var e = Object.keys(s);
  if (Object.getOwnPropertySymbols) {
    var i = Object.getOwnPropertySymbols(s);
    t && (i = i.filter(function(n) {
      return Object.getOwnPropertyDescriptor(s, n).enumerable;
    })), e.push.apply(e, i);
  }
  return e;
}
function m(s) {
  for (var t = 1; t < arguments.length; t++) {
    var e = arguments[t] != null ? arguments[t] : {};
    t % 2 ? Lt(Object(e), !0).forEach(function(i) {
      g(s, i, e[i]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(s, Object.getOwnPropertyDescriptors(e)) : Lt(Object(e)).forEach(function(i) {
      Object.defineProperty(s, i, Object.getOwnPropertyDescriptor(e, i));
    });
  }
  return s;
}
const $t = {
  pointer: {
    start: "down",
    change: "move",
    end: "up"
  },
  mouse: {
    start: "down",
    change: "move",
    end: "up"
  },
  touch: {
    start: "start",
    change: "move",
    end: "end"
  },
  gesture: {
    start: "start",
    change: "change",
    end: "end"
  }
};
function Rt(s) {
  return s ? s[0].toUpperCase() + s.slice(1) : "";
}
const we = ["enter", "leave"];
function Ee(s = !1, t) {
  return s && !we.includes(t);
}
function Te(s, t = "", e = !1) {
  const i = $t[s], n = i && i[t] || t;
  return "on" + Rt(s) + Rt(n) + (Ee(e, n) ? "Capture" : "");
}
const xe = ["gotpointercapture", "lostpointercapture"];
function Se(s) {
  let t = s.substring(2).toLowerCase();
  const e = !!~t.indexOf("passive");
  e && (t = t.replace("passive", ""));
  const i = xe.includes(t) ? "capturecapture" : "capture", n = !!~t.indexOf(i);
  return n && (t = t.replace("capture", "")), {
    device: t,
    capture: n,
    passive: e
  };
}
function Ie(s, t = "") {
  const e = $t[s], i = e && e[t] || t;
  return s + i;
}
function ot(s) {
  return "touches" in s;
}
function Zt(s) {
  return ot(s) ? "touch" : "pointerType" in s ? s.pointerType : "mouse";
}
function Ae(s) {
  return Array.from(s.touches).filter((t) => {
    var e, i;
    return t.target === s.currentTarget || ((e = s.currentTarget) === null || e === void 0 || (i = e.contains) === null || i === void 0 ? void 0 : i.call(e, t.target));
  });
}
function De(s) {
  return s.type === "touchend" || s.type === "touchcancel" ? s.changedTouches : s.targetTouches;
}
function Ft(s) {
  return ot(s) ? De(s)[0] : s;
}
function dt(s, t) {
  try {
    const e = t.clientX - s.clientX, i = t.clientY - s.clientY, n = (t.clientX + s.clientX) / 2, o = (t.clientY + s.clientY) / 2, r = Math.hypot(e, i);
    return {
      angle: -(Math.atan2(e, i) * 180) / Math.PI,
      distance: r,
      origin: [n, o]
    };
  } catch {
  }
  return null;
}
function Pe(s) {
  return Ae(s).map((t) => t.identifier);
}
function Vt(s, t) {
  const [e, i] = Array.from(s.touches).filter((n) => t.includes(n.identifier));
  return dt(e, i);
}
function lt(s) {
  const t = Ft(s);
  return ot(s) ? t.identifier : t.pointerId;
}
function H(s) {
  const t = Ft(s);
  return [t.clientX, t.clientY];
}
const Kt = 40, Yt = 800;
function qt(s) {
  let {
    deltaX: t,
    deltaY: e,
    deltaMode: i
  } = s;
  return i === 1 ? (t *= Kt, e *= Kt) : i === 2 && (t *= Yt, e *= Yt), [t, e];
}
function Oe(s) {
  var t, e;
  const {
    scrollX: i,
    scrollY: n,
    scrollLeft: o,
    scrollTop: r
  } = s.currentTarget;
  return [(t = i ?? o) !== null && t !== void 0 ? t : 0, (e = n ?? r) !== null && e !== void 0 ? e : 0];
}
function ke(s) {
  const t = {};
  if ("buttons" in s && (t.buttons = s.buttons), "shiftKey" in s) {
    const {
      shiftKey: e,
      altKey: i,
      metaKey: n,
      ctrlKey: o
    } = s;
    Object.assign(t, {
      shiftKey: e,
      altKey: i,
      metaKey: n,
      ctrlKey: o
    });
  }
  return t;
}
function nt(s, ...t) {
  return typeof s == "function" ? s(...t) : s;
}
function Me() {
}
function Ce(...s) {
  return s.length === 0 ? Me : s.length === 1 ? s[0] : function() {
    let t;
    for (const e of s)
      t = e.apply(this, arguments) || t;
    return t;
  };
}
function Ut(s, t) {
  return Object.assign({}, t, s || {});
}
const Ne = 32;
class Jt {
  constructor(t, e, i) {
    this.ctrl = t, this.args = e, this.key = i, this.state || (this.state = {}, this.computeValues([0, 0]), this.computeInitial(), this.init && this.init(), this.reset());
  }
  get state() {
    return this.ctrl.state[this.key];
  }
  set state(t) {
    this.ctrl.state[this.key] = t;
  }
  get shared() {
    return this.ctrl.state.shared;
  }
  get eventStore() {
    return this.ctrl.gestureEventStores[this.key];
  }
  get timeoutStore() {
    return this.ctrl.gestureTimeoutStores[this.key];
  }
  get config() {
    return this.ctrl.config[this.key];
  }
  get sharedConfig() {
    return this.ctrl.config.shared;
  }
  get handler() {
    return this.ctrl.handlers[this.key];
  }
  reset() {
    const {
      state: t,
      shared: e,
      ingKey: i,
      args: n
    } = this;
    e[i] = t._active = t.active = t._blocked = t._force = !1, t._step = [!1, !1], t.intentional = !1, t._movement = [0, 0], t._distance = [0, 0], t._direction = [0, 0], t._delta = [0, 0], t._bounds = [[-1 / 0, 1 / 0], [-1 / 0, 1 / 0]], t.args = n, t.axis = void 0, t.memo = void 0, t.elapsedTime = t.timeDelta = 0, t.direction = [0, 0], t.distance = [0, 0], t.overflow = [0, 0], t._movementBound = [!1, !1], t.velocity = [0, 0], t.movement = [0, 0], t.delta = [0, 0], t.timeStamp = 0;
  }
  start(t) {
    const e = this.state, i = this.config;
    e._active || (this.reset(), this.computeInitial(), e._active = !0, e.target = t.target, e.currentTarget = t.currentTarget, e.lastOffset = i.from ? nt(i.from, e) : e.offset, e.offset = e.lastOffset, e.startTime = e.timeStamp = t.timeStamp);
  }
  computeValues(t) {
    const e = this.state;
    e._values = t, e.values = this.config.transform(t);
  }
  computeInitial() {
    const t = this.state;
    t._initial = t._values, t.initial = t.values;
  }
  compute(t) {
    const {
      state: e,
      config: i,
      shared: n
    } = this;
    e.args = this.args;
    let o = 0;
    if (t && (e.event = t, i.preventDefault && t.cancelable && e.event.preventDefault(), e.type = t.type, n.touches = this.ctrl.pointerIds.size || this.ctrl.touchIds.size, n.locked = !!document.pointerLockElement, Object.assign(n, ke(t)), n.down = n.pressed = n.buttons % 2 === 1 || n.touches > 0, o = t.timeStamp - e.timeStamp, e.timeStamp = t.timeStamp, e.elapsedTime = e.timeStamp - e.startTime), e._active) {
      const A = e._delta.map(Math.abs);
      f.addTo(e._distance, A);
    }
    this.axisIntent && this.axisIntent(t);
    const [r, c] = e._movement, [l, h] = i.threshold, {
      _step: a,
      values: x
    } = e;
    if (i.hasCustomTransform ? (a[0] === !1 && (a[0] = Math.abs(r) >= l && x[0]), a[1] === !1 && (a[1] = Math.abs(c) >= h && x[1])) : (a[0] === !1 && (a[0] = Math.abs(r) >= l && Math.sign(r) * l), a[1] === !1 && (a[1] = Math.abs(c) >= h && Math.sign(c) * h)), e.intentional = a[0] !== !1 || a[1] !== !1, !e.intentional) return;
    const _ = [0, 0];
    if (i.hasCustomTransform) {
      const [A, F] = x;
      _[0] = a[0] !== !1 ? A - a[0] : 0, _[1] = a[1] !== !1 ? F - a[1] : 0;
    } else
      _[0] = a[0] !== !1 ? r - a[0] : 0, _[1] = a[1] !== !1 ? c - a[1] : 0;
    this.restrictToAxis && !e._blocked && this.restrictToAxis(_);
    const D = e.offset, E = e._active && !e._blocked || e.active;
    E && (e.first = e._active && !e.active, e.last = !e._active && e.active, e.active = n[this.ingKey] = e._active, t && (e.first && ("bounds" in i && (e._bounds = nt(i.bounds, e)), this.setup && this.setup()), e.movement = _, this.computeOffset()));
    const [P, I] = e.offset, [[X, v], [C, d]] = e._bounds;
    e.overflow = [P < X ? -1 : P > v ? 1 : 0, I < C ? -1 : I > d ? 1 : 0], e._movementBound[0] = e.overflow[0] ? e._movementBound[0] === !1 ? e._movement[0] : e._movementBound[0] : !1, e._movementBound[1] = e.overflow[1] ? e._movementBound[1] === !1 ? e._movement[1] : e._movementBound[1] : !1;
    const rt = e._active ? i.rubberband || [0, 0] : [0, 0];
    if (e.offset = ve(e._bounds, e.offset, rt), e.delta = f.sub(e.offset, D), this.computeMovement(), E && (!e.last || o > Ne)) {
      e.delta = f.sub(e.offset, D);
      const A = e.delta.map(Math.abs);
      f.addTo(e.distance, A), e.direction = e.delta.map(Math.sign), e._direction = e._delta.map(Math.sign), !e.first && o > 0 && (e.velocity = [A[0] / o, A[1] / o], e.timeDelta = o);
    }
  }
  emit() {
    const t = this.state, e = this.shared, i = this.config;
    if (t._active || this.clean(), (t._blocked || !t.intentional) && !t._force && !i.triggerAllEvents) return;
    const n = this.handler(m(m(m({}, e), t), {}, {
      [this.aliasKey]: t.values
    }));
    n !== void 0 && (t.memo = n);
  }
  clean() {
    this.eventStore.clean(), this.timeoutStore.clean();
  }
}
function Le([s, t], e) {
  const i = Math.abs(s), n = Math.abs(t);
  if (i > n && i > e)
    return "x";
  if (n > i && n > e)
    return "y";
}
class $ extends Jt {
  constructor(...t) {
    super(...t), g(this, "aliasKey", "xy");
  }
  reset() {
    super.reset(), this.state.axis = void 0;
  }
  init() {
    this.state.offset = [0, 0], this.state.lastOffset = [0, 0];
  }
  computeOffset() {
    this.state.offset = f.add(this.state.lastOffset, this.state.movement);
  }
  computeMovement() {
    this.state.movement = f.sub(this.state.offset, this.state.lastOffset);
  }
  axisIntent(t) {
    const e = this.state, i = this.config;
    if (!e.axis && t) {
      const n = typeof i.axisThreshold == "object" ? i.axisThreshold[Zt(t)] : i.axisThreshold;
      e.axis = Le(e._movement, n);
    }
    e._blocked = (i.lockDirection || !!i.axis) && !e.axis || !!i.axis && i.axis !== e.axis;
  }
  restrictToAxis(t) {
    if (this.config.axis || this.config.lockDirection)
      switch (this.state.axis) {
        case "x":
          t[1] = 0;
          break;
        case "y":
          t[0] = 0;
          break;
      }
  }
}
const jt = (s) => s, Bt = 0.15, mt = {
  enabled(s = !0) {
    return s;
  },
  eventOptions(s, t, e) {
    return m(m({}, e.shared.eventOptions), s);
  },
  preventDefault(s = !1) {
    return s;
  },
  triggerAllEvents(s = !1) {
    return s;
  },
  rubberband(s = 0) {
    switch (s) {
      case !0:
        return [Bt, Bt];
      case !1:
        return [0, 0];
      default:
        return f.toVector(s);
    }
  },
  from(s) {
    if (typeof s == "function") return s;
    if (s != null) return f.toVector(s);
  },
  transform(s, t, e) {
    const i = s || e.shared.transform;
    if (this.hasCustomTransform = !!i, process.env.NODE_ENV === "development") {
      const n = i || jt;
      return (o) => {
        const r = n(o);
        return (!isFinite(r[0]) || !isFinite(r[1])) && console.warn(`[@use-gesture]: config.transform() must produce a valid result, but it was: [${r[0]},${[1]}]`), r;
      };
    }
    return i || jt;
  },
  threshold(s) {
    return f.toVector(s, 0);
  }
};
process.env.NODE_ENV === "development" && Object.assign(mt, {
  domTarget(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `domTarget` option has been renamed to `target`.");
    return NaN;
  },
  lockDirection(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `lockDirection` option has been merged with `axis`. Use it as in `{ axis: 'lock' }`");
    return NaN;
  },
  initial(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `initial` option has been renamed to `from`.");
    return NaN;
  }
});
const Re = 0, M = m(m({}, mt), {}, {
  axis(s, t, {
    axis: e
  }) {
    if (this.lockDirection = e === "lock", !this.lockDirection) return e;
  },
  axisThreshold(s = Re) {
    return s;
  },
  bounds(s = {}) {
    if (typeof s == "function")
      return (o) => M.bounds(s(o));
    if ("current" in s)
      return () => s.current;
    if (typeof HTMLElement == "function" && s instanceof HTMLElement)
      return s;
    const {
      left: t = -1 / 0,
      right: e = 1 / 0,
      top: i = -1 / 0,
      bottom: n = 1 / 0
    } = s;
    return [[t, e], [i, n]];
  }
}), Ht = {
  ArrowRight: (s, t = 1) => [s * t, 0],
  ArrowLeft: (s, t = 1) => [-1 * s * t, 0],
  ArrowUp: (s, t = 1) => [0, -1 * s * t],
  ArrowDown: (s, t = 1) => [0, s * t]
};
class Ve extends $ {
  constructor(...t) {
    super(...t), g(this, "ingKey", "dragging");
  }
  reset() {
    super.reset();
    const t = this.state;
    t._pointerId = void 0, t._pointerActive = !1, t._keyboardActive = !1, t._preventScroll = !1, t._delayed = !1, t.swipe = [0, 0], t.tap = !1, t.canceled = !1, t.cancel = this.cancel.bind(this);
  }
  setup() {
    const t = this.state;
    if (t._bounds instanceof HTMLElement) {
      const e = t._bounds.getBoundingClientRect(), i = t.currentTarget.getBoundingClientRect(), n = {
        left: e.left - i.left + t.offset[0],
        right: e.right - i.right + t.offset[0],
        top: e.top - i.top + t.offset[1],
        bottom: e.bottom - i.bottom + t.offset[1]
      };
      t._bounds = M.bounds(n);
    }
  }
  cancel() {
    const t = this.state;
    t.canceled || (t.canceled = !0, t._active = !1, setTimeout(() => {
      this.compute(), this.emit();
    }, 0));
  }
  setActive() {
    this.state._active = this.state._pointerActive || this.state._keyboardActive;
  }
  clean() {
    this.pointerClean(), this.state._pointerActive = !1, this.state._keyboardActive = !1, super.clean();
  }
  pointerDown(t) {
    const e = this.config, i = this.state;
    if (t.buttons != null && (Array.isArray(e.pointerButtons) ? !e.pointerButtons.includes(t.buttons) : e.pointerButtons !== -1 && e.pointerButtons !== t.buttons)) return;
    const n = this.ctrl.setEventIds(t);
    e.pointerCapture && t.target.setPointerCapture(t.pointerId), !(n && n.size > 1 && i._pointerActive) && (this.start(t), this.setupPointer(t), i._pointerId = lt(t), i._pointerActive = !0, this.computeValues(H(t)), this.computeInitial(), e.preventScrollAxis && Zt(t) !== "mouse" ? (i._active = !1, this.setupScrollPrevention(t)) : e.delay > 0 ? (this.setupDelayTrigger(t), e.triggerAllEvents && (this.compute(t), this.emit())) : this.startPointerDrag(t));
  }
  startPointerDrag(t) {
    const e = this.state;
    e._active = !0, e._preventScroll = !0, e._delayed = !1, this.compute(t), this.emit();
  }
  pointerMove(t) {
    const e = this.state, i = this.config;
    if (!e._pointerActive) return;
    const n = lt(t);
    if (e._pointerId !== void 0 && n !== e._pointerId) return;
    const o = H(t);
    if (document.pointerLockElement === t.target ? e._delta = [t.movementX, t.movementY] : (e._delta = f.sub(o, e._values), this.computeValues(o)), f.addTo(e._movement, e._delta), this.compute(t), e._delayed && e.intentional) {
      this.timeoutStore.remove("dragDelay"), e.active = !1, this.startPointerDrag(t);
      return;
    }
    if (i.preventScrollAxis && !e._preventScroll)
      if (e.axis)
        if (e.axis === i.preventScrollAxis || i.preventScrollAxis === "xy") {
          e._active = !1, this.clean();
          return;
        } else {
          this.timeoutStore.remove("startPointerDrag"), this.startPointerDrag(t);
          return;
        }
      else
        return;
    this.emit();
  }
  pointerUp(t) {
    this.ctrl.setEventIds(t);
    try {
      this.config.pointerCapture && t.target.hasPointerCapture(t.pointerId) && t.target.releasePointerCapture(t.pointerId);
    } catch {
      process.env.NODE_ENV === "development" && console.warn("[@use-gesture]: If you see this message, it's likely that you're using an outdated version of `@react-three/fiber`. \n\nPlease upgrade to the latest version.");
    }
    const e = this.state, i = this.config;
    if (!e._active || !e._pointerActive) return;
    const n = lt(t);
    if (e._pointerId !== void 0 && n !== e._pointerId) return;
    this.state._pointerActive = !1, this.setActive(), this.compute(t);
    const [o, r] = e._distance;
    if (e.tap = o <= i.tapsThreshold && r <= i.tapsThreshold, e.tap && i.filterTaps)
      e._force = !0;
    else {
      const [c, l] = e._delta, [h, a] = e._movement, [x, _] = i.swipe.velocity, [D, E] = i.swipe.distance, P = i.swipe.duration;
      if (e.elapsedTime < P) {
        const I = Math.abs(c / e.timeDelta), X = Math.abs(l / e.timeDelta);
        I > x && Math.abs(h) > D && (e.swipe[0] = Math.sign(c)), X > _ && Math.abs(a) > E && (e.swipe[1] = Math.sign(l));
      }
    }
    this.emit();
  }
  pointerClick(t) {
    !this.state.tap && t.detail > 0 && (t.preventDefault(), t.stopPropagation());
  }
  setupPointer(t) {
    const e = this.config, i = e.device;
    if (process.env.NODE_ENV === "development")
      try {
        if (i === "pointer" && e.preventScrollDelay === void 0) {
          const n = "uv" in t ? t.sourceEvent.currentTarget : t.currentTarget;
          window.getComputedStyle(n).touchAction === "auto" && console.warn("[@use-gesture]: The drag target has its `touch-action` style property set to `auto`. It is recommended to add `touch-action: 'none'` so that the drag gesture behaves correctly on touch-enabled devices. For more information read this: https://use-gesture.netlify.app/docs/extras/#touch-action.\n\nThis message will only show in development mode. It won't appear in production. If this is intended, you can ignore it.", n);
        }
      } catch {
      }
    e.pointerLock && t.currentTarget.requestPointerLock(), e.pointerCapture || (this.eventStore.add(this.sharedConfig.window, i, "change", this.pointerMove.bind(this)), this.eventStore.add(this.sharedConfig.window, i, "end", this.pointerUp.bind(this)), this.eventStore.add(this.sharedConfig.window, i, "cancel", this.pointerUp.bind(this)));
  }
  pointerClean() {
    this.config.pointerLock && document.pointerLockElement === this.state.currentTarget && document.exitPointerLock();
  }
  preventScroll(t) {
    this.state._preventScroll && t.cancelable && t.preventDefault();
  }
  setupScrollPrevention(t) {
    this.state._preventScroll = !1, Ke(t);
    const e = this.eventStore.add(this.sharedConfig.window, "touch", "change", this.preventScroll.bind(this), {
      passive: !1
    });
    this.eventStore.add(this.sharedConfig.window, "touch", "end", e), this.eventStore.add(this.sharedConfig.window, "touch", "cancel", e), this.timeoutStore.add("startPointerDrag", this.startPointerDrag.bind(this), this.config.preventScrollDelay, t);
  }
  setupDelayTrigger(t) {
    this.state._delayed = !0, this.timeoutStore.add("dragDelay", () => {
      this.state._step = [0, 0], this.startPointerDrag(t);
    }, this.config.delay);
  }
  keyDown(t) {
    const e = Ht[t.key];
    if (e) {
      const i = this.state, n = t.shiftKey ? 10 : t.altKey ? 0.1 : 1;
      this.start(t), i._delta = e(this.config.keyboardDisplacement, n), i._keyboardActive = !0, f.addTo(i._movement, i._delta), this.compute(t), this.emit();
    }
  }
  keyUp(t) {
    t.key in Ht && (this.state._keyboardActive = !1, this.setActive(), this.compute(t), this.emit());
  }
  bind(t) {
    const e = this.config.device;
    t(e, "start", this.pointerDown.bind(this)), this.config.pointerCapture && (t(e, "change", this.pointerMove.bind(this)), t(e, "end", this.pointerUp.bind(this)), t(e, "cancel", this.pointerUp.bind(this)), t("lostPointerCapture", "", this.pointerUp.bind(this))), this.config.keys && (t("key", "down", this.keyDown.bind(this)), t("key", "up", this.keyUp.bind(this))), this.config.filterTaps && t("click", "", this.pointerClick.bind(this), {
      capture: !0,
      passive: !1
    });
  }
}
function Ke(s) {
  "persist" in s && typeof s.persist == "function" && s.persist();
}
const Z = typeof window < "u" && window.document && window.document.createElement;
function Qt() {
  return Z && "ontouchstart" in window;
}
function Ye() {
  return Qt() || Z && window.navigator.maxTouchPoints > 1;
}
function Ue() {
  return Z && "onpointerdown" in window;
}
function je() {
  return Z && "exitPointerLock" in window.document;
}
function Be() {
  try {
    return "constructor" in GestureEvent;
  } catch {
    return !1;
  }
}
const S = {
  isBrowser: Z,
  gesture: Be(),
  touch: Qt(),
  touchscreen: Ye(),
  pointer: Ue(),
  pointerLock: je()
}, He = 250, Xe = 180, We = 0.5, ze = 50, Ge = 250, $e = 10, Xt = {
  mouse: 0,
  touch: 0,
  pen: 8
}, te = m(m({}, M), {}, {
  device(s, t, {
    pointer: {
      touch: e = !1,
      lock: i = !1,
      mouse: n = !1
    } = {}
  }) {
    return this.pointerLock = i && S.pointerLock, S.touch && e ? "touch" : this.pointerLock ? "mouse" : S.pointer && !n ? "pointer" : S.touch ? "touch" : "mouse";
  },
  preventScrollAxis(s, t, {
    preventScroll: e
  }) {
    if (this.preventScrollDelay = typeof e == "number" ? e : e || e === void 0 && s ? He : void 0, !(!S.touchscreen || e === !1))
      return s || (e !== void 0 ? "y" : void 0);
  },
  pointerCapture(s, t, {
    pointer: {
      capture: e = !0,
      buttons: i = 1,
      keys: n = !0
    } = {}
  }) {
    return this.pointerButtons = i, this.keys = n, !this.pointerLock && this.device === "pointer" && e;
  },
  threshold(s, t, {
    filterTaps: e = !1,
    tapsThreshold: i = 3,
    axis: n = void 0
  }) {
    const o = f.toVector(s, e ? i : n ? 1 : 0);
    return this.filterTaps = e, this.tapsThreshold = i, o;
  },
  swipe({
    velocity: s = We,
    distance: t = ze,
    duration: e = Ge
  } = {}) {
    return {
      velocity: this.transform(f.toVector(s)),
      distance: this.transform(f.toVector(t)),
      duration: e
    };
  },
  delay(s = 0) {
    switch (s) {
      case !0:
        return Xe;
      case !1:
        return 0;
      default:
        return s;
    }
  },
  axisThreshold(s) {
    return s ? m(m({}, Xt), s) : Xt;
  },
  keyboardDisplacement(s = $e) {
    return s;
  }
});
process.env.NODE_ENV === "development" && Object.assign(te, {
  useTouch(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `useTouch` option has been renamed to `pointer.touch`. Use it as in `{ pointer: { touch: true } }`.");
    return NaN;
  },
  experimental_preventWindowScrollY(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `experimental_preventWindowScrollY` option has been renamed to `preventScroll`.");
    return NaN;
  },
  swipeVelocity(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `swipeVelocity` option has been renamed to `swipe.velocity`. Use it as in `{ swipe: { velocity: 0.5 } }`.");
    return NaN;
  },
  swipeDistance(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `swipeDistance` option has been renamed to `swipe.distance`. Use it as in `{ swipe: { distance: 50 } }`.");
    return NaN;
  },
  swipeDuration(s) {
    if (s !== void 0)
      throw Error("[@use-gesture]: `swipeDuration` option has been renamed to `swipe.duration`. Use it as in `{ swipe: { duration: 250 } }`.");
    return NaN;
  }
});
function ee(s) {
  const [t, e] = s.overflow, [i, n] = s._delta, [o, r] = s._direction;
  (t < 0 && i > 0 && o < 0 || t > 0 && i < 0 && o > 0) && (s._movement[0] = s._movementBound[0]), (e < 0 && n > 0 && r < 0 || e > 0 && n < 0 && r > 0) && (s._movement[1] = s._movementBound[1]);
}
const Ze = 30, Fe = 100;
class qe extends Jt {
  constructor(...t) {
    super(...t), g(this, "ingKey", "pinching"), g(this, "aliasKey", "da");
  }
  init() {
    this.state.offset = [1, 0], this.state.lastOffset = [1, 0], this.state._pointerEvents = /* @__PURE__ */ new Map();
  }
  reset() {
    super.reset();
    const t = this.state;
    t._touchIds = [], t.canceled = !1, t.cancel = this.cancel.bind(this), t.turns = 0;
  }
  computeOffset() {
    const {
      type: t,
      movement: e,
      lastOffset: i
    } = this.state;
    t === "wheel" ? this.state.offset = f.add(e, i) : this.state.offset = [(1 + e[0]) * i[0], e[1] + i[1]];
  }
  computeMovement() {
    const {
      offset: t,
      lastOffset: e
    } = this.state;
    this.state.movement = [t[0] / e[0], t[1] - e[1]];
  }
  axisIntent() {
    const t = this.state, [e, i] = t._movement;
    if (!t.axis) {
      const n = Math.abs(e) * Ze - Math.abs(i);
      n < 0 ? t.axis = "angle" : n > 0 && (t.axis = "scale");
    }
  }
  restrictToAxis(t) {
    this.config.lockDirection && (this.state.axis === "scale" ? t[1] = 0 : this.state.axis === "angle" && (t[0] = 0));
  }
  cancel() {
    const t = this.state;
    t.canceled || setTimeout(() => {
      t.canceled = !0, t._active = !1, this.compute(), this.emit();
    }, 0);
  }
  touchStart(t) {
    this.ctrl.setEventIds(t);
    const e = this.state, i = this.ctrl.touchIds;
    if (e._active && e._touchIds.every((o) => i.has(o)) || i.size < 2) return;
    this.start(t), e._touchIds = Array.from(i).slice(0, 2);
    const n = Vt(t, e._touchIds);
    n && this.pinchStart(t, n);
  }
  pointerStart(t) {
    if (t.buttons != null && t.buttons % 2 !== 1) return;
    this.ctrl.setEventIds(t), t.target.setPointerCapture(t.pointerId);
    const e = this.state, i = e._pointerEvents, n = this.ctrl.pointerIds;
    if (e._active && Array.from(i.keys()).every((r) => n.has(r)) || (i.size < 2 && i.set(t.pointerId, t), e._pointerEvents.size < 2)) return;
    this.start(t);
    const o = dt(...Array.from(i.values()));
    o && this.pinchStart(t, o);
  }
  pinchStart(t, e) {
    const i = this.state;
    i.origin = e.origin, this.computeValues([e.distance, e.angle]), this.computeInitial(), this.compute(t), this.emit();
  }
  touchMove(t) {
    if (!this.state._active) return;
    const e = Vt(t, this.state._touchIds);
    e && this.pinchMove(t, e);
  }
  pointerMove(t) {
    const e = this.state._pointerEvents;
    if (e.has(t.pointerId) && e.set(t.pointerId, t), !this.state._active) return;
    const i = dt(...Array.from(e.values()));
    i && this.pinchMove(t, i);
  }
  pinchMove(t, e) {
    const i = this.state, n = i._values[1], o = e.angle - n;
    let r = 0;
    Math.abs(o) > 270 && (r += Math.sign(o)), this.computeValues([e.distance, e.angle - 360 * r]), i.origin = e.origin, i.turns = r, i._movement = [i._values[0] / i._initial[0] - 1, i._values[1] - i._initial[1]], this.compute(t), this.emit();
  }
  touchEnd(t) {
    this.ctrl.setEventIds(t), this.state._active && this.state._touchIds.some((e) => !this.ctrl.touchIds.has(e)) && (this.state._active = !1, this.compute(t), this.emit());
  }
  pointerEnd(t) {
    const e = this.state;
    this.ctrl.setEventIds(t);
    try {
      t.target.releasePointerCapture(t.pointerId);
    } catch {
    }
    e._pointerEvents.has(t.pointerId) && e._pointerEvents.delete(t.pointerId), e._active && e._pointerEvents.size < 2 && (e._active = !1, this.compute(t), this.emit());
  }
  gestureStart(t) {
    t.cancelable && t.preventDefault();
    const e = this.state;
    e._active || (this.start(t), this.computeValues([t.scale, t.rotation]), e.origin = [t.clientX, t.clientY], this.compute(t), this.emit());
  }
  gestureMove(t) {
    if (t.cancelable && t.preventDefault(), !this.state._active) return;
    const e = this.state;
    this.computeValues([t.scale, t.rotation]), e.origin = [t.clientX, t.clientY];
    const i = e._movement;
    e._movement = [t.scale - 1, t.rotation], e._delta = f.sub(e._movement, i), this.compute(t), this.emit();
  }
  gestureEnd(t) {
    this.state._active && (this.state._active = !1, this.compute(t), this.emit());
  }
  wheel(t) {
    const e = this.config.modifierKey;
    e && (Array.isArray(e) ? !e.find((i) => t[i]) : !t[e]) || (this.state._active ? this.wheelChange(t) : this.wheelStart(t), this.timeoutStore.add("wheelEnd", this.wheelEnd.bind(this)));
  }
  wheelStart(t) {
    this.start(t), this.wheelChange(t);
  }
  wheelChange(t) {
    "uv" in t || (t.cancelable && t.preventDefault(), process.env.NODE_ENV === "development" && !t.defaultPrevented && console.warn("[@use-gesture]: To properly support zoom on trackpads, try using the `target` option.\n\nThis message will only appear in development mode."));
    const i = this.state;
    i._delta = [-qt(t)[1] / Fe * i.offset[0], 0], f.addTo(i._movement, i._delta), ee(i), this.state.origin = [t.clientX, t.clientY], this.compute(t), this.emit();
  }
  wheelEnd() {
    this.state._active && (this.state._active = !1, this.compute(), this.emit());
  }
  bind(t) {
    const e = this.config.device;
    e && (t(e, "start", this[e + "Start"].bind(this)), t(e, "change", this[e + "Move"].bind(this)), t(e, "end", this[e + "End"].bind(this)), t(e, "cancel", this[e + "End"].bind(this)), t("lostPointerCapture", "", this[e + "End"].bind(this))), this.config.pinchOnWheel && t("wheel", "", this.wheel.bind(this), {
      passive: !1
    });
  }
}
const Je = m(m({}, mt), {}, {
  device(s, t, {
    shared: e,
    pointer: {
      touch: i = !1
    } = {}
  }) {
    if (e.target && !S.touch && S.gesture) return "gesture";
    if (S.touch && i) return "touch";
    if (S.touchscreen) {
      if (S.pointer) return "pointer";
      if (S.touch) return "touch";
    }
  },
  bounds(s, t, {
    scaleBounds: e = {},
    angleBounds: i = {}
  }) {
    const n = (r) => {
      const c = Ut(nt(e, r), {
        min: -1 / 0,
        max: 1 / 0
      });
      return [c.min, c.max];
    }, o = (r) => {
      const c = Ut(nt(i, r), {
        min: -1 / 0,
        max: 1 / 0
      });
      return [c.min, c.max];
    };
    return typeof e != "function" && typeof i != "function" ? [n(), o()] : (r) => [n(r), o(r)];
  },
  threshold(s, t, e) {
    return this.lockDirection = e.axis === "lock", f.toVector(s, this.lockDirection ? [0.1, 3] : 0);
  },
  modifierKey(s) {
    return s === void 0 ? "ctrlKey" : s;
  },
  pinchOnWheel(s = !0) {
    return s;
  }
});
class Qe extends $ {
  constructor(...t) {
    super(...t), g(this, "ingKey", "moving");
  }
  move(t) {
    this.config.mouseOnly && t.pointerType !== "mouse" || (this.state._active ? this.moveChange(t) : this.moveStart(t), this.timeoutStore.add("moveEnd", this.moveEnd.bind(this)));
  }
  moveStart(t) {
    this.start(t), this.computeValues(H(t)), this.compute(t), this.computeInitial(), this.emit();
  }
  moveChange(t) {
    if (!this.state._active) return;
    const e = H(t), i = this.state;
    i._delta = f.sub(e, i._values), f.addTo(i._movement, i._delta), this.computeValues(e), this.compute(t), this.emit();
  }
  moveEnd(t) {
    this.state._active && (this.state._active = !1, this.compute(t), this.emit());
  }
  bind(t) {
    t("pointer", "change", this.move.bind(this)), t("pointer", "leave", this.moveEnd.bind(this));
  }
}
const ts = m(m({}, M), {}, {
  mouseOnly: (s = !0) => s
});
class es extends $ {
  constructor(...t) {
    super(...t), g(this, "ingKey", "scrolling");
  }
  scroll(t) {
    this.state._active || this.start(t), this.scrollChange(t), this.timeoutStore.add("scrollEnd", this.scrollEnd.bind(this));
  }
  scrollChange(t) {
    t.cancelable && t.preventDefault();
    const e = this.state, i = Oe(t);
    e._delta = f.sub(i, e._values), f.addTo(e._movement, e._delta), this.computeValues(i), this.compute(t), this.emit();
  }
  scrollEnd() {
    this.state._active && (this.state._active = !1, this.compute(), this.emit());
  }
  bind(t) {
    t("scroll", "", this.scroll.bind(this));
  }
}
const ss = M;
class is extends $ {
  constructor(...t) {
    super(...t), g(this, "ingKey", "wheeling");
  }
  wheel(t) {
    this.state._active || this.start(t), this.wheelChange(t), this.timeoutStore.add("wheelEnd", this.wheelEnd.bind(this));
  }
  wheelChange(t) {
    const e = this.state;
    e._delta = qt(t), f.addTo(e._movement, e._delta), ee(e), this.compute(t), this.emit();
  }
  wheelEnd() {
    this.state._active && (this.state._active = !1, this.compute(), this.emit());
  }
  bind(t) {
    t("wheel", "", this.wheel.bind(this));
  }
}
const ns = M;
class os extends $ {
  constructor(...t) {
    super(...t), g(this, "ingKey", "hovering");
  }
  enter(t) {
    this.config.mouseOnly && t.pointerType !== "mouse" || (this.start(t), this.computeValues(H(t)), this.compute(t), this.emit());
  }
  leave(t) {
    if (this.config.mouseOnly && t.pointerType !== "mouse") return;
    const e = this.state;
    if (!e._active) return;
    e._active = !1;
    const i = H(t);
    e._movement = e._delta = f.sub(i, e._values), this.computeValues(i), this.compute(t), e.delta = e.movement, this.emit();
  }
  bind(t) {
    t("pointer", "enter", this.enter.bind(this)), t("pointer", "leave", this.leave.bind(this));
  }
}
const rs = m(m({}, M), {}, {
  mouseOnly: (s = !0) => s
}), gt = /* @__PURE__ */ new Map(), pt = /* @__PURE__ */ new Map();
function as(s) {
  gt.set(s.key, s.engine), pt.set(s.key, s.resolver);
}
const cs = {
  key: "drag",
  engine: Ve,
  resolver: te
}, us = {
  key: "hover",
  engine: os,
  resolver: rs
}, ls = {
  key: "move",
  engine: Qe,
  resolver: ts
}, hs = {
  key: "pinch",
  engine: qe,
  resolver: Je
}, fs = {
  key: "scroll",
  engine: es,
  resolver: ss
}, ds = {
  key: "wheel",
  engine: is,
  resolver: ns
};
function ps(s, t) {
  if (s == null) return {};
  var e = {}, i = Object.keys(s), n, o;
  for (o = 0; o < i.length; o++)
    n = i[o], !(t.indexOf(n) >= 0) && (e[n] = s[n]);
  return e;
}
function ms(s, t) {
  if (s == null) return {};
  var e = ps(s, t), i, n;
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(s);
    for (n = 0; n < o.length; n++)
      i = o[n], !(t.indexOf(i) >= 0) && Object.prototype.propertyIsEnumerable.call(s, i) && (e[i] = s[i]);
  }
  return e;
}
const gs = {
  target(s) {
    if (s)
      return () => "current" in s ? s.current : s;
  },
  enabled(s = !0) {
    return s;
  },
  window(s = S.isBrowser ? window : void 0) {
    return s;
  },
  eventOptions({
    passive: s = !0,
    capture: t = !1
  } = {}) {
    return {
      passive: s,
      capture: t
    };
  },
  transform(s) {
    return s;
  }
}, _s = ["target", "eventOptions", "window", "enabled", "transform"];
function et(s = {}, t) {
  const e = {};
  for (const [i, n] of Object.entries(t))
    switch (typeof n) {
      case "function":
        if (process.env.NODE_ENV === "development") {
          const o = n.call(e, s[i], i, s);
          Number.isNaN(o) || (e[i] = o);
        } else
          e[i] = n.call(e, s[i], i, s);
        break;
      case "object":
        e[i] = et(s[i], n);
        break;
      case "boolean":
        n && (e[i] = s[i]);
        break;
    }
  return e;
}
function vs(s, t, e = {}) {
  const i = s, {
    target: n,
    eventOptions: o,
    window: r,
    enabled: c,
    transform: l
  } = i, h = ms(i, _s);
  if (e.shared = et({
    target: n,
    eventOptions: o,
    window: r,
    enabled: c,
    transform: l
  }, gs), t) {
    const a = pt.get(t);
    e[t] = et(m({
      shared: e.shared
    }, h), a);
  } else
    for (const a in h) {
      const x = pt.get(a);
      if (x)
        e[a] = et(m({
          shared: e.shared
        }, h[a]), x);
      else if (process.env.NODE_ENV === "development" && !["drag", "pinch", "scroll", "wheel", "move", "hover"].includes(a)) {
        if (a === "domTarget")
          throw Error("[@use-gesture]: `domTarget` option has been renamed to `target`.");
        console.warn(`[@use-gesture]: Unknown config key \`${a}\` was used. Please read the documentation for further information.`);
      }
    }
  return e;
}
class se {
  constructor(t, e) {
    g(this, "_listeners", /* @__PURE__ */ new Set()), this._ctrl = t, this._gestureKey = e;
  }
  add(t, e, i, n, o) {
    const r = this._listeners, c = Ie(e, i), l = this._gestureKey ? this._ctrl.config[this._gestureKey].eventOptions : {}, h = m(m({}, l), o);
    t.addEventListener(c, n, h);
    const a = () => {
      t.removeEventListener(c, n, h), r.delete(a);
    };
    return r.add(a), a;
  }
  clean() {
    this._listeners.forEach((t) => t()), this._listeners.clear();
  }
}
class ys {
  constructor() {
    g(this, "_timeouts", /* @__PURE__ */ new Map());
  }
  add(t, e, i = 140, ...n) {
    this.remove(t), this._timeouts.set(t, window.setTimeout(e, i, ...n));
  }
  remove(t) {
    const e = this._timeouts.get(t);
    e && window.clearTimeout(e);
  }
  clean() {
    this._timeouts.forEach((t) => void window.clearTimeout(t)), this._timeouts.clear();
  }
}
class bs {
  constructor(t) {
    g(this, "gestures", /* @__PURE__ */ new Set()), g(this, "_targetEventStore", new se(this)), g(this, "gestureEventStores", {}), g(this, "gestureTimeoutStores", {}), g(this, "handlers", {}), g(this, "config", {}), g(this, "pointerIds", /* @__PURE__ */ new Set()), g(this, "touchIds", /* @__PURE__ */ new Set()), g(this, "state", {
      shared: {
        shiftKey: !1,
        metaKey: !1,
        ctrlKey: !1,
        altKey: !1
      }
    }), ws(this, t);
  }
  setEventIds(t) {
    if (ot(t))
      return this.touchIds = new Set(Pe(t)), this.touchIds;
    if ("pointerId" in t)
      return t.type === "pointerup" || t.type === "pointercancel" ? this.pointerIds.delete(t.pointerId) : t.type === "pointerdown" && this.pointerIds.add(t.pointerId), this.pointerIds;
  }
  applyHandlers(t, e) {
    this.handlers = t, this.nativeHandlers = e;
  }
  applyConfig(t, e) {
    this.config = vs(t, e, this.config);
  }
  clean() {
    this._targetEventStore.clean();
    for (const t of this.gestures)
      this.gestureEventStores[t].clean(), this.gestureTimeoutStores[t].clean();
  }
  effect() {
    return this.config.shared.target && this.bind(), () => this._targetEventStore.clean();
  }
  bind(...t) {
    const e = this.config.shared, i = {};
    let n;
    if (!(e.target && (n = e.target(), !n))) {
      if (e.enabled) {
        for (const r of this.gestures) {
          const c = this.config[r], l = Wt(i, c.eventOptions, !!n);
          if (c.enabled) {
            const h = gt.get(r);
            new h(this, t, r).bind(l);
          }
        }
        const o = Wt(i, e.eventOptions, !!n);
        for (const r in this.nativeHandlers)
          o(r, "", (c) => this.nativeHandlers[r](m(m({}, this.state.shared), {}, {
            event: c,
            args: t
          })), void 0, !0);
      }
      for (const o in i)
        i[o] = Ce(...i[o]);
      if (!n) return i;
      for (const o in i) {
        const {
          device: r,
          capture: c,
          passive: l
        } = Se(o);
        this._targetEventStore.add(n, r, "", i[o], {
          capture: c,
          passive: l
        });
      }
    }
  }
}
function j(s, t) {
  s.gestures.add(t), s.gestureEventStores[t] = new se(s, t), s.gestureTimeoutStores[t] = new ys();
}
function ws(s, t) {
  t.drag && j(s, "drag"), t.wheel && j(s, "wheel"), t.scroll && j(s, "scroll"), t.move && j(s, "move"), t.pinch && j(s, "pinch"), t.hover && j(s, "hover");
}
const Wt = (s, t, e) => (i, n, o, r = {}, c = !1) => {
  var l, h;
  const a = (l = r.capture) !== null && l !== void 0 ? l : t.capture, x = (h = r.passive) !== null && h !== void 0 ? h : t.passive;
  let _ = c ? i : Te(i, n, a);
  e && x && (_ += "Passive"), s[_] = s[_] || [], s[_].push(o);
}, Es = /^on(Drag|Wheel|Scroll|Move|Pinch|Hover)/;
function Ts(s) {
  const t = {}, e = {}, i = /* @__PURE__ */ new Set();
  for (let n in s)
    Es.test(n) ? (i.add(RegExp.lastMatch), e[n] = s[n]) : t[n] = s[n];
  return [e, t, i];
}
function B(s, t, e, i, n, o) {
  if (!s.has(e)) return;
  if (!gt.has(i)) {
    process.env.NODE_ENV === "development" && console.warn(`[@use-gesture]: You've created a custom handler that that uses the \`${i}\` gesture but isn't properly configured.

Please add \`${i}Action\` when creating your handler.`);
    return;
  }
  const r = e + "Start", c = e + "End", l = (h) => {
    let a;
    return h.first && r in t && t[r](h), e in t && (a = t[e](h)), h.last && c in t && t[c](h), a;
  };
  n[i] = l, o[i] = o[i] || {};
}
function xs(s, t) {
  const [e, i, n] = Ts(s), o = {};
  return B(n, e, "onDrag", "drag", o, t), B(n, e, "onWheel", "wheel", o, t), B(n, e, "onScroll", "scroll", o, t), B(n, e, "onPinch", "pinch", o, t), B(n, e, "onMove", "move", o, t), B(n, e, "onHover", "hover", o, t), {
    handlers: o,
    config: t,
    nativeHandlers: i
  };
}
function Ss(s, t = {}, e, i) {
  const n = ut.useMemo(() => new bs(s), []);
  if (n.applyHandlers(s, i), n.applyConfig(t, e), ut.useEffect(n.effect.bind(n)), ut.useEffect(() => n.clean.bind(n), []), t.target === void 0)
    return n.bind.bind(n);
}
function Is(s) {
  return s.forEach(as), function(e, i) {
    const {
      handlers: n,
      nativeHandlers: o,
      config: r
    } = xs(e, i || {});
    return Ss(n, r, void 0, o);
  };
}
function As(s, t) {
  return Is([cs, hs, fs, ds, ls, us])(s, t || {});
}
const st = 300;
function Ds(s) {
  let t = !1;
  return s.wheelDeltaY ? s.wheelDeltaY === s.deltaY * -3 && (t = !0) : s.deltaMode === 0 && (t = !0), t;
}
function Ps() {
  return window.matchMedia("(pointer: coarse)").matches;
}
const ie = zt({
  mouseType: "mouse",
  pan: { x: 0, y: 0 },
  zoom: 1,
  containerRef: {},
  contentRef: {},
  zoomIn: () => {
  },
  zoomOut: () => {
  },
  setZoom: () => {
  },
  setPan: () => {
  },
  resetPanZoom: () => {
  }
}), ne = zt({
  animated: !1,
  visible: !1
});
let ht = null, tt = null, ft = null;
const G = [], Os = 16;
function Ls({
  children: s,
  forceMouseType: t,
  initialPan: e,
  isPanBounded: i = !0,
  panBoundPadding: n = { top: 0, right: 0, bottom: 0, left: 0 },
  panBoundDelay: o = 300,
  centerOnMount: r = !0,
  centerOnMountDelay: c = 3 * 1e3 / 60,
  initialZoom: l,
  isZoomBounded: h = !0,
  minZoom: a = 0.5,
  maxZoom: x = 2,
  zoomStrength: _ = 0.05,
  onChange: D
}) {
  const E = Ot(null), P = Ot(null), [I, X] = O(t ?? Ps() ? "touchscreen" : "mouse"), [v, C] = O(e ?? { x: 0, y: 0 }), [d, rt] = O(l ?? 1), [A, F] = O(!1), [at, ct] = O(r), [N, L] = O(!1), [_t, vt] = O(!1), [yt, oe] = O(!1), bt = T((u) => {
    if (t || I === "touchscreen") return;
    G.length > Os && G.shift(), G.push(Ds(u) ? "touchpad" : "mouse");
    const p = G.filter((w) => w === "touchpad").length, b = G.filter((w) => w === "mouse").length, y = p > b ? "touchpad" : "mouse";
    return X(y), y;
  }, [
    t,
    I
  ]), R = T(() => {
    ht && clearTimeout(ht), tt && clearTimeout(tt);
  }, []), wt = T(() => {
    ft && clearTimeout(ft);
  }, []), V = T((u, p = d) => {
    var It, At, Dt, Pt;
    if (!i) return u;
    const b = ((It = E.current) == null ? void 0 : It.clientWidth) ?? 0, y = ((At = E.current) == null ? void 0 : At.clientHeight) ?? 0, w = ((Dt = P.current) == null ? void 0 : Dt.scrollWidth) ?? 0, k = ((Pt = P.current) == null ? void 0 : Pt.scrollHeight) ?? 0, U = n.top * p, J = -n.bottom * p + y - k * p, Q = n.left * p, Tt = -n.right * p + b - w * p, de = Math.min(Q, Tt), pe = Math.max(Q, Tt), me = Math.min(U, J), ge = Math.max(U, J), xt = w * p, St = k * p, z = {
      x: Math.max(de, Math.min(u.x, pe)),
      y: Math.max(me, Math.min(u.y, ge))
    };
    return typeof e > "u" && (at && z.x === Q && xt < b && (z.x += (b - xt) / 2 - Q, ct(!1)), at && z.y === U && St < y && (z.y += (y - St) / 2 - U, ct(!1))), z;
  }, [
    e,
    i,
    n,
    d,
    at
  ]), W = T((u) => {
    N || (R(), L(!1), C((p) => V({
      x: p.x - u.x,
      y: p.y - u.y
    })));
  }, [
    N,
    R,
    V
  ]), K = T((u, p) => {
    if (N) return;
    R(), L(!1);
    const b = h ? Math.min(x, Math.max(a, u)) : u, y = {
      x: v.x - p.x * (b - d),
      y: v.y - p.y * (b - d)
    }, w = V(y, b);
    C(y), rt(b), !(w.x === y.x && w.y === y.y) && (ht = setTimeout(() => {
      L(!0), C(w), tt = setTimeout(() => {
        L(!1);
      }, st);
    }, o));
  }, [
    h,
    a,
    x,
    o,
    N,
    v,
    d,
    R,
    V
  ]), re = T((u) => {
    I !== "touchpad" && W({
      x: -u.delta[0],
      y: -u.delta[1]
    });
  }, [
    I,
    W
  ]), ae = T((u) => {
    if (u.event.stopPropagation(), A || !E.current) return;
    const p = bt(u.event);
    if (p === "touchpad")
      W({
        x: u.delta[0],
        y: u.delta[1]
      });
    else if (p === "mouse") {
      const { top: b, left: y } = E.current.getBoundingClientRect(), w = d * (1 - Math.sign(u.delta[1]) * _);
      K(w, {
        x: (u.event.clientX - y - v.x) / d,
        y: (u.event.clientY - b - v.y) / d
      });
    }
  }, [
    _,
    v,
    d,
    A,
    W,
    K,
    bt
  ]), ce = T((u) => {
    if (!E.current) return;
    u.event.preventDefault(), u.event.stopPropagation();
    const [p] = u.direction, [b, y] = u.origin, { top: w, left: k } = E.current.getBoundingClientRect(), U = d * (1 - Math.sign(-p) * _), J = {
      x: (b - k - v.x) / d,
      y: (y - w - v.y) / d
    };
    K(U, J);
  }, [
    _,
    v,
    d,
    K
  ]), ue = T(() => {
    F(!0);
  }, []), le = T(() => {
    F(!1);
  }, []), q = T((u = e ?? { x: 0, y: 0 }) => {
    R(), L(!0), C(V(u)), tt = setTimeout(() => {
      L(!1);
    }, st);
  }, [
    e,
    V,
    R
  ]), Y = T((u = l ?? 1) => {
    if (wt(), vt(!0), !E.current) return;
    const { top: p, left: b, width: y, height: w } = E.current.getBoundingClientRect(), k = {
      x: (y / 2 - b - v.x) / d,
      y: (w / 2 - p - v.y) / d
    };
    K(u, k), ft = setTimeout(() => {
      vt(!1);
    }, st);
  }, [
    v,
    d,
    l,
    K,
    wt
  ]), Et = T(() => {
    Y(), setTimeout(() => {
      q();
    }, 2);
  }, [
    Y,
    q
  ]);
  As(
    {
      onDrag: re,
      onWheel: ae,
      onPinch: ce,
      onPinchStart: ue,
      onPinchEnd: le
    },
    {
      target: E,
      eventOptions: {
        passive: !1
      }
    }
  ), kt(() => {
    setTimeout(() => {
      W({ x: 0, y: 0 }), ct(!1), oe(!0);
    }, c);
  }, []), kt(() => {
    D == null || D(v, d);
  }, [
    v,
    d,
    D
  ]);
  const he = Mt(() => ({
    mouseType: I,
    pan: v,
    zoom: d,
    containerRef: E,
    contentRef: P,
    setPan: q,
    setZoom: Y,
    zoomIn: () => Y(d + 0.5),
    zoomOut: () => Y(d - 0.5),
    resetPanZoom: Et
  }), [
    I,
    v,
    d,
    q,
    Y,
    Et
  ]), fe = Mt(() => ({
    animated: N || _t,
    visible: yt
  }), [
    N,
    _t,
    yt
  ]);
  return /* @__PURE__ */ it(ie.Provider, { value: he, children: /* @__PURE__ */ it(ne.Provider, { value: fe, children: s }) });
}
function ks() {
  return Gt(ie);
}
function Ms() {
  return Gt(ne);
}
function Rs({
  children: s,
  containerClassName: t = "",
  containerStyle: e = {},
  contentClassName: i = "",
  contentStyle: n = {}
}) {
  const { containerRef: o, contentRef: r, pan: c, zoom: l } = ks(), { animated: h, visible: a } = Ms();
  return /* @__PURE__ */ it(
    "div",
    {
      ref: o,
      className: t,
      style: {
        ...e,
        overflow: "hidden",
        touchAction: "none"
      },
      children: /* @__PURE__ */ it(
        "div",
        {
          ref: r,
          className: i,
          style: {
            ...n,
            width: "fit-content",
            transform: `translate(${c.x}px, ${c.y}px) scale(${l})`,
            transformOrigin: "0 0",
            transition: h ? `transform ${st}ms ease-out` : void 0,
            visibility: a ? "visible" : "hidden"
          },
          children: s
        }
      )
    }
  );
}
export {
  Rs as PanZoom,
  Ls as PanZoomProvider,
  ks as usePanZoom
};
